var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.eventForReason'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.eventForReason'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1),_c('CCol',{attrs:{"sm":"12","lg":"4"}},[_c('CSelect',{attrs:{"options":_vm.EventOptions,"label":_vm.$t('label.event'),"horizontal":{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-8'},"addLabelClasses":"text-right"},on:{"change":function($event){return _vm.getEventReasonList()}},model:{value:(_vm.EventId),callback:function ($$v) {_vm.EventId=$$v},expression:"EventId"}})],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item, index }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.edit')+' '+_vm.$t('label.eventForReason'),
                    placement: 'top-end'
                  }),expression:"{\n                    content: $t('label.edit')+' '+$t('label.eventForReason'),\n                    placement: 'top-end'\n                  }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","square":"","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1),_c('modalEventReason',{attrs:{"modal":_vm.modalEventReasonCreate,"isEdit":_vm.isEdit,"title":_vm.titleModal,"eventReasonItems":_vm.eventReasonItems},on:{"update:modal":function($event){_vm.modalEventReasonCreate=$event},"Update":_vm.Update,"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }