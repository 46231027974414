<template>
  <main>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end py-2">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="toggleCreate"
              v-c-tooltip="{
                content: $t('label.eventForReason'),
                placement: 'top-end'
              }"
            >
              <CIcon name="cil-playlist-add"/>&nbsp;
              {{$t('label.nuevo')}}
            </CButton>
          </CCol>
          <CCol sm="12" lg="4">
				<CSelect
            :options="EventOptions"
            v-model="EventId"
            :label="$t('label.event')"
            :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right"
            @change="getEventReasonList()"
          />
			</CCol>
        </CRow>
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedList"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        > 
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item, index }">
            <td>
                <CButton
                  size="sm"
                  square
                  color="edit"
                  class="d-flex align-items-center"
                  @click="toggleEdit(item)"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.eventForReason'),
                    placement: 'top-end'
                  }"
                >
                  <CIcon name="pencil" />
                </CButton>
            
            </td>
          </template>
        </dataTableExtended>
      </CCardBody>
    <modalEventReason
      :modal.sync="modalEventReasonCreate"
      :isEdit="isEdit"
      :title="titleModal"
      :eventReasonItems="eventReasonItems"
      @Update="Update"
      @close="closeModal"
    />
  </main>
</template>
<script>
import modalEventReason from './modal-event-reason';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';


function data() {
  return {
    EventId: '',
    items: [],
    modalEventReasonCreate: false,
    eventReasonItems: {},
    loading: false,
    isEdit: false,
    titleModal: '',
    EventList: [],
  };
}

//methods
async function getEventList() {
  this.loading = true;
  this.$http.get('Event-list', { EventGpoId: '' })
    .then((response) => {
      let List = response.data.data
      if (List&&List.length!=0) {
        this.EventList = List;
      }else{
        this.EventList = [];
      }
       this.getEventReasonList();
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}

async function getEventReasonList() {
  this.loading = true;
  this.$http
    .get('EventReason-list',{EventId: this.EventId})
    .then((response) => (this.items = response.data.data))
    .finally(() => {
      this.loading = false;
    });
}

function toggleCreate() {
  this.modalEventReasonCreate = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.eventForReason');
}

function toggleEdit(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1)
  this.eventReasonItems = item;
  this.isEdit = true;
  this.modalEventReasonCreate = true;
  let title = this.eventReasonItems[`EventReasonName${_lang}`];
  this.titleModal =  this.$t('label.edit')+' '+this.$t('label.eventForReason')+': '+title;
}

function Update() {
  this.modalEventReasonCreate = false;
  this.isEdit = false;
  this.getEventReasonList();
}

function closeModal(){
  this.eventReasonItems = {};
  this.modalEventReasonCreate= false;
  this.isEdit= false;
  this.titleModal = '';
}

//computed
function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-left', _style: 'width:5%; text-align:left', filter: false},
     { key: 'EventName',label: this.$t('label.event'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', },
     { key: 'TpEirName',label: this.$t('label.eirType'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', },
     { key: 'EventReasonNameEs',label: this.$t('label.eventForReason')+' '+'(ES)',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
     { key: 'EventReasonNameEn',label: this.$t('label.eventForReason')+' '+'(EN)',_style: 'width:27%;',_classes: 'text-uppercase text-center'},
     { key: 'TransaLogin',label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:12%' },
     { key: 'FormatedDate',label: this.$t('label.date'),_classes:'text-center', _style:'width:12%' },
     { key: 'Status',label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:12%' },
     { key: 'options',label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
  ];
}

function formatedList() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.items.map((item) => {
    return {
      ...item,
      EventName: item[`EventName${_lang}`] ??  'N/A',
      TpEirName: item[`TpEirName${_lang}`] ??  'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      TransaLogin: item.TransaLogin ?? 'N/A',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function EventOptions(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.EventList.map(function(e){
      chart.push({
        value: e.EventId, 
        label: e[`EventName${_lang}`],
      })    
    })
    return chart;
  }
}

export default {
  name: 'event-reason-index',
  components: { modalEventReason },
  data,
  mixins: [GeneralMixin],
  props: {
		Tab: {
			type: Number,
			default: 0,
		}
	},
  methods: {
    getEventList,
    getEventReasonList,
    toggleCreate,
    toggleEdit,
    Update,
    closeModal
  },
  computed: {
    formatedList,
    fields,
    EventOptions
  },
  watch: {
		Tab: async function (val) {
			if (val==4) {
        await this.getEventList();
			}
		}
	}
};
</script>
